import { setCookie, getCookie } from "../../../lib/session";

export function SetStorage(
  key = "locationIs",
  value,
  res = undefined,
  expiresOn = { expires: 1 }
) {
  setCookie(key, value, res, expiresOn);
}

export function GetStorage(key = "locationIs", req = undefined) {
  return getCookie(key, req);
}

export async function IstokenExpired() {
  let data = null;
  let resultIs = false;
  data = await GetStorage("locationIs");
  if (data) {
    resultIs = false;
  } else {
    resultIs = true;
  }
  return resultIs;
}
