import React from "react";
import { mLog } from "utils/logger";
import {
  retrieveDataFromAsyncStorage,
  storeDataToAsyncStorage
} from "screens/common/utils";
import { getFromIp } from "./utils";
import { SetStorage, GetStorage } from "./storage";

export async function SetLocation(
  type,
  name,
  lat,
  long,
  placeId,
  city,
  res = undefined,
  req = undefined
) {
  if (!type || !name) {
    mLog("type and name has to be passed while setting location");
    return;
  }
  mLog("==== in set loction", { lat, long, name, placeId, city });
  let locationObj = {
    latitude: null,
    longitude: null,
    city: null,
    placeId: null
  };

  if (process.browser) {
    let locationStorage = await retrieveDataFromAsyncStorage(
      "locationEntryMode"
    );
    locationStorage = JSON.parse(locationStorage);
    locationStorage = {
      ...locationStorage,
      [type]: { city, name, placeId, latitude: lat, longitude: long }
    };

    // console.log("location Storage", locationStorage);
    // console.log("location Object", locationObj);
    locationObj =
      locationStorage.user_entered_location ||
      locationStorage.current_location ||
      locationStorage.ip_detected;
    await storeDataToAsyncStorage(
      "locationEntryMode",
      JSON.stringify(locationStorage)
    );
  } else {
    locationObj.name = name;
  }
  SetStorage("locationIs", JSON.stringify(locationObj), res, { expires: 1 });
}

export async function GetLocation(client, res = undefined, req = undefined) {
  const locationIs = GetStorage("locationIs", req);
  const locationObj = locationIs
    ? JSON.parse(decodeURIComponent(locationIs))
    : {};

  let name = locationObj.name || (await getFromIp(client, res)) || null;

  const locality = name;
  const { city, latitude, longitude, placeId } = locationObj;

  // handling exceptions on Location name
  // if (name === "Bangalore" && city === "Bengaluru") {
  //   name = name;
  // } else if (city && name !== city) {
  //   name = `${name}, ${city}`;
  // } else {
  //   name = name;
  // }

  return {
    name,
    latitude,
    longitude,
    city,
    placeId,
    locality
  };
}

export const LocationContext = React.createContext();
