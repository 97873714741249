/* eslint-disable camelcase */
import { buttonType } from "screens/common/constants";
import {
  getCandidateApplication,
  jobApplyMutation,
  deleteJobFromCache
} from "screens/common/JobCard/CTA/utils/common";
import {
  getTotalApplicationCount,
  isPaidJob
} from "screens/common/utils/common";
import querystring from "screens/common/utils/QueryString";
import {
  pushClevertapEvent,
  pushClevertapProfile
} from "utils/clevertapEvents";
import { getCMSScore } from "screens/Application/utils/query";
import getData from "utils/getData";
import { sendEventToGTM } from "utils/googleTagManagerEvents";
import NavHistory from "utils/routing/NavHistory";
import { navigate } from "utils/routing/NavRouter";
import {
  triggerAdjustApplicationEvent,
  triggerAdjustTrustedJobAppEvent,
  triggerAdjustOnApplicationCreationEvent
} from "utils/adjustEvents";
import { Platform } from "react-native";
import { retrieveDataFromAsyncStorage } from "screens/common/utils";
import { setValue } from "utils/LocalKeyValueStorage";
import { CALL_HR } from "screens/common/queries";
import {
  graphQLClientMutationHelper,
  getGraphQLErrorCode
} from "utils/graphQL";
import { mLog } from "utils/logger";

export const saveContextAndNavigateToRegister = ({
  jobId,
  jobSlug,
  pricingPlanType,
  pathname,
  option
}) => {
  const route = "jobDetail";
  const params = {
    id: jobId,
    slug: jobSlug,
    pricingPlan: pricingPlanType,
    source: pathname,
    candstate: "false"
  };

  // Expire after 15 minutes
  setValue("invokeAction", option, { expires: 1 / 96 });

  navigate("loginRegistration", {
    route,
    params: JSON.stringify(params)
  });
};

export const navigateUserAfterApplying = ({ application, props, bType }) => {
  const { jobId, jobSlug, pricingPlanType, isWalkIn, referrer, setRedirectionFlow = { status: false, onSetRedirect: () => {} } } = props;
  let autoRedirect = {
    goBack: false,
    setRedirect: null
  };
  if(setRedirectionFlow?.status) {
    if(pricingPlanType === "FREE" || pricingPlanType === "JOB_POSTING") {
      autoRedirect = {
        goBack: false,
        setRedirect: setRedirectionFlow?.onSetRedirect
      };
    } else {
      autoRedirect = {
        goBack: true,
        setRedirect: null
      };
    }
  }

  let route = "";
  let params = {};

  if (application === null) {
    route = "similarJobs";
    params = {
      id: jobId,
      slug: jobSlug,
      notify: "apply",
      referrer,
      autoRedirect: autoRedirect,
    };
  } else if (isWalkIn) {
    route = "fixInterview";
    params = {
      slug: application.id,
      ref: "application"
    };
  } else if (!isPaidJob(pricingPlanType)) {
    route = "similarJobs";
    params = {
      id: jobId,
      slug: jobSlug,
      appId: application.id,
      notify: "apply",
      referrer,
      autoRedirect: autoRedirect,
    };
  }

  // Used for different functions
  const extraParams = {
    callHR: bType === buttonType.CallHR // Indicates, whether from apply or call hr
    
  };

  // Redirect to CMS form for free jobs
  if (pricingPlanType === "FREE" || pricingPlanType === "JOB_POSTING") {
    params.notify = "apply";

    navigate("cmsForm", {
      jobId,
      nextPage: route,
      nextParams: querystring.stringify(params),
      extraParams: querystring.stringify(extraParams),
      referrer: referrer || "jobList",
      autoRedirect
    });
  } else {
    navigate(route, params);
  }
};

export const navigateUserBeforeApplying = ({
  isJobScreeningRequired,
  isResumeUploadRequired,
  props,
  bType
}) => {
  const { jobId, jobSlug, queryVariables, referrer, jobRecoSource } = props;

  const nextPage = "similarJobs";
  const nextParams = {
    id: jobId,
    slug: jobSlug
  };

  // Used for different functions
  const extraParams = {
    from: referrer || "jobList", // Indicates, from where job apply is started
    queryVariables: JSON.stringify(queryVariables), // Used for deleting job from cache
    callHR: bType === buttonType.CallHR // Indicates, whether from apply or call hr
  };

  // Add nextPage and nextParams in params object to forcefully redirect to page after application creation
  const commonParams = {
    nextPage,
    nextParams: querystring.stringify(nextParams),
    extraParams: querystring.stringify(extraParams)
  };

  let route = "";
  let params = {};

  if (isJobScreeningRequired) {
    route = "applicationForm";
    params = {
      ...commonParams,
      jobId,
      performASR: true,
      referrer: referrer || "jobList",
      jobRecoSource
    };
  } else if (isResumeUploadRequired) {
    route = "resumeForm";
    params = {
      ...commonParams,
      jobId,
      performASR: true
    };
  }

  navigate(route, params);
};

export const handleApply = async ({
  props,
  onStart,
  onError,
  onSuccess,
  bType
}) => {
  const {
    jobId,
    jobGet,
    jobSlug,
    isWalkIn,
    pricingPlanType,
    isResumeRequired,
    clevertapProps,
    user,
    sources,
    query,
    client,
    queryVariables,
    jobRecoSource
  } = props;
  const variantValue = await retrieveDataFromAsyncStorage("callHrVariant");
  const clevertapAttr = { ...clevertapProps };
  if (bType === buttonType.CallHR) {
    clevertapAttr.Type = "CALL HR";
    clevertapAttr.Test_Case = variantValue;
  }

  const resume = getData(user, "stakeholder.resume");
  const candidateId = getData(user, "stakeholder.id");

  const isJobScreeningRequired = isPaidJob(pricingPlanType);
  const isResumeUploadRequired =
    isJobScreeningRequired && isResumeRequired && !resume;

  if (user && user.mobile_verified) {
    // call mutation function
    if (bType === buttonType.CallHR) {
      callHRMutation(jobId, candidateId, client);
    }

    // If screening or resume required then redirect to respective page
    if (isJobScreeningRequired || isResumeUploadRequired) {
      navigateUserBeforeApplying({
        isJobScreeningRequired,
        isResumeUploadRequired,
        props,
        bType
      });
      return;
    }

    onStart(bType);

    // clevertapProps["Apply Page"] = `jobList - logged in`;

    if (variantValue === "B" && bType === buttonType.CallHR) {
      onSuccess(null, bType);
      return;
    }
    const { data, error } = await jobApplyMutation({
      jobGet,
      jobId,
      isWalkIn,
      sources,
      client
    });

    let applicationData = getData(data, "mutate.apply", {});
    const applicationCount = await getTotalApplicationCount(client);
    const locationType = await retrieveDataFromAsyncStorage("locationType");

    if (error) {
      const errorCode = getGraphQLErrorCode(error);
      if (errorCode === "DUPLICATE_ENTRY") {
        applicationData = await getCandidateApplication(jobId, client);
      } else {
        pushClevertapEvent("Application Creation Response", {
          Status: "F",
          Error: error.message,
          Location_type: locationType,
          ...clevertapAttr,
          'Job Source': jobRecoSource,
        });

        if (onError) {
          onError(bType);
        }
        return;
      }
    }
    if (applicationData) {
      sendEventToGTM("Application Submit Success", {
        CandidateID: getData(applicationData, "candidate.id"),
        UserID: user.id,
        ApplicationCount: applicationCount,
        pricing_plan: pricingPlanType
      });
      pushClevertapProfile({
        Identity: user.id,
        Application_Count: applicationCount
      });

      if (Platform.OS === "android") {
        triggerAdjustApplicationEvent(
          "UserID",
          user.id,
          "App_ID",
          getData(applicationData, "id"),
          "pricing_plan",
          pricingPlanType
        );
        if (
          pricingPlanType === "POSTPAID" ||
          pricingPlanType === "PREPAID" ||
          pricingPlanType === "TEMP_STAFFING"
        ) {
          triggerAdjustTrustedJobAppEvent(
            "UserID",
            user.id,
            "App_ID",
            getData(applicationData, "id"),
            "pricing_plan",
            pricingPlanType
          );
        }
        if (applicationCount === 1) {
          triggerAdjustOnApplicationCreationEvent("UserID", user.id);
        }
      }

      const cmsData = await getCMSScore(applicationData.id, client);
      let cmsScore;
      if (cmsData) {
        cmsScore = cmsData.node.cms;
      }
      pushClevertapEvent("Application Creation Response", {
        application_id: applicationData.id,
        application_count: applicationCount,
        Status: "S",
        Location_type: locationType,
        cms_score: cmsScore?.score,
        'Job Source': jobRecoSource,
        ...clevertapAttr
      });

      onSuccess(applicationData, bType);
    } else {
      // When application is not found navigate to JD page
      // Remove job from cache
      deleteJobFromCache(jobId, queryVariables, client);
      navigate("jobDetail", {
        id: jobId,
        slug: jobSlug
      });
    }
  } else if (user && !user.mobile_verified) {
    let currentURL = NavHistory.get();
    currentURL = currentURL[currentURL.length - 1];
    currentURL.params = { ...currentURL.params, applyTo: jobId, ...query };

    navigate("verify", {
      page: "mobile",
      goBackTo: JSON.stringify(currentURL),
      bookGo: true
    });
  }
};

const callHRMutation = async (jobId, candidateId, client) => {
  const now = new Date();
  const date = new Date(
    now.getTime() - now.getTimezoneOffset() * 60000
  ).toISOString();
  const dateSplitUp = date.split("T");
  const interaction_time = `${dateSplitUp[0]} ${dateSplitUp[1].split(".")[0]}`;

  const payload = {
    candidate_id: candidateId,
    job_id: jobId,
    interaction_time
  };

  const { data, error } = await graphQLClientMutationHelper(client)({
    mutation: CALL_HR,
    variables: { payload }
  });

  if (error) {
    mLog("Error occured in capture call hr interaction:", error);
  } else {
    mLog("Success in capture call hr interaction:", data);
  }

  return {
    data,
    error
  };
};
