import PropTypes from "prop-types";
import SActionCard from "./ActionCard";

SActionCard.defaultProps = {
  visible: false,
  onCancel: undefined,
  topComponent: null,
  bottomComponent: null
}

SActionCard.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func, // Called when a user clicks backdrop.
  topComponent: PropTypes.element,
  bottomComponent: PropTypes.element
};

export default SActionCard;
