import React from "react";
import {
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
  View
} from "react-native";

const Content = ({
  modalContainerStyle,
  scrollViewStyle,
  topComponent,
  bottomComponent,
  children
}) => {
  return (
    <TouchableWithoutFeedback pointerEvents="box-none">
      <View style={[styles.container, modalContainerStyle]}>
        {topComponent}
        <ScrollView style={[styles.scrollView, scrollViewStyle]}>
          {children}
        </ScrollView>
        {bottomComponent}
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 1010
  },
  scrollView: {
    height: "auto"
  }
});

export default Content;
