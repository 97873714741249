import gql from "graphql-tag";

export const SEARCH_JOB_QUERY_TOTAL_COUNT = gql`
  query searchTotalCount(
    $searchKeyword: String
    $location: String
    $first: Int
    $filter: JobFilter
    $after: Int
  ) {
    searchJobsV2(
      location: $location
      filter: $filter
      first: $first
      query: $searchKeyword
      after: $after
    ) {
      __typename
      totalCount
    }
  }
`;

export const GET_FLEXI_USER_STATUS = gql`
  query checkUserStatus {
    checkUserStatus{
      code
      message
      response{
        user_role
        is_bank_detail_filled
        is_basic_detail_filled
        user_id
        user_timer_freshchat
        is_address_detailed_filled 
        driving_license
        profile_photo
        pan
        aadhaar
        candidate_address
        rc
        cms_questions{
          id
          question
          answer_options
          cms_parameter
          cms_parameter_type
        }
        required_docs
      }
    }
  }
`;

export const SEARCH_JOB_QUERY = gql`
  query SearchJobs(
    $searchKeyword: String
    $location: String
    $first: Int
    $filter: JobFilter
    $after: Int
    $sort: SortingInput
    $variant: String
  ) {
    searchJobsV2(
      location: $location
      filter: $filter
      first: $first
      query: $searchKeyword
      after: $after
      sort: $sort
      variant: $variant
    ) {
      __typename
      totalCount
      jobSource
      edges {
        __typename
        id
        score
        explain
        title
        designations
        is_featured
        slug
        vacancies
        opened_on
        created
        hiring_org_name
        organization {
          __typename
          id
          name
          popular_name
          slug
          is_hr_agency
        }
        offer {
          __typename
          max_offered_salary
          min_offered_salary
          salary_format
        }
        tags
        application_metrics {
          __typename
          joinings
          selections
          previous_interviews
          upcoming_interviews
        }
        expectation {
          __typename
          work_exp_requirements {
            __typename
            max_experience
            min_experience
          }
        }
        pricing_plan_type
        client_approval_required
        is_screening_required
        recent_slot
        places {
          place_id
          short_formatted_address
          location
        }
        locations {
          place {
            place_id
            short_formatted_address
            location
            city
            locality
          }
        }
        functional_area {
          name
          id
        }
        share_contact_to_public
        primary_job_scheduler {
          user {
            id
            mobile
            first_name
          }
        }
        associated_cse {
          user {
            id
            mobile
            first_name
          }
        }
      }
    }
    featuredJobs(
      location: $location
      filter: $filter
      query: $searchKeyword
      fallbackSearch: false
    ) {
      __typename
      totalCount
      edges {
        __typename
        id
        score
        explain
        title
        designations
        is_featured
        slug
        vacancies
        opened_on
        created
        hiring_org_name
        organization {
          __typename
          id
          name
          popular_name
          slug
          is_hr_agency
        }
        offer {
          __typename
          max_offered_salary
          min_offered_salary
          salary_format
        }
        tags
        application_metrics {
          __typename
          joinings
          selections
          previous_interviews
          upcoming_interviews
        }
        expectation {
          __typename
          work_exp_requirements {
            __typename
            max_experience
            min_experience
          }
        }
        pricing_plan_type
        client_approval_required
        is_screening_required
        recent_slot
        places {
          place_id
          short_formatted_address
          location
        }
        locations {
          place {
            place_id
            short_formatted_address
            location
            city
            locality
          }
        }
        functional_area {
          name
          id
        }
        share_contact_to_public
        primary_job_scheduler {
          user {
            id
            mobile
            first_name
          }
        }
        associated_cse {
          user {
            id
            mobile
            first_name
          }
        }
      }
    }
    getKeywordSeoByLocation(keyword: $searchKeyword, city: $location) {
      id
      content
      meta_description
      meta_keyword
      title
      h1
    }
  }
`;

export const SEARCH_JOB_SEO_QUERY = gql`
  query SearchJobs(
    $searchKeyword: String
    $location: String
    $first: Int
    $filter: JobFilter
    $after: Int
    $sort: SortingInput
    $seoKeyword: String
  ) {
    searchJobsV2(
      location: $location
      filter: $filter
      first: $first
      query: $searchKeyword
      after: $after
      sort: $sort
    ) {
      __typename
      totalCount
      jobSource
      edges {
        __typename
        id
        score
        explain
        title
        designations
        is_featured
        slug
        hiring_org_name
        organization {
          __typename
          id
          name
          popular_name
          slug
          is_hr_agency
        }
        offer {
          __typename
          max_offered_salary
          min_offered_salary
          salary_format
        }
        tags
        application_metrics {
          __typename
          joinings
          selections
          previous_interviews
          upcoming_interviews
        }
        expectation {
          __typename
          work_exp_requirements {
            __typename
            max_experience
            min_experience
          }
        }
        pricing_plan_type
        client_approval_required
        is_screening_required
        recent_slot
        places {
          place_id
          short_formatted_address
          location
        }
        locations {
          place {
            place_id
            short_formatted_address
            location
            city
            locality
          }
        }
        functional_area {
          name
          id
        }
        share_contact_to_public
        primary_job_scheduler {
          user {
            id
            mobile
            first_name
          }
        }
        associated_cse {
          user {
            id
            mobile
            first_name
          }
        }
      }
    }
    featuredJobs(
      location: $location
      filter: $filter
      query: $searchKeyword
      fallbackSearch: false
    ) {
      __typename
      totalCount
      edges {
        __typename
        id
        score
        explain
        title
        designations
        is_featured
        slug
        hiring_org_name
        organization {
          __typename
          id
          name
          popular_name
          slug
          is_hr_agency
        }
        offer {
          __typename
          max_offered_salary
          min_offered_salary
          salary_format
        }
        tags
        application_metrics {
          __typename
          joinings
          selections
          previous_interviews
          upcoming_interviews
        }
        expectation {
          __typename
          work_exp_requirements {
            __typename
            max_experience
            min_experience
          }
        }
        pricing_plan_type
        client_approval_required
        is_screening_required
        recent_slot
        places {
          place_id
          short_formatted_address
          location
        }
        locations {
          place {
            place_id
            short_formatted_address
            location
            city
            locality
          }
        }
        functional_area {
          name
          id
        }
        share_contact_to_public
        primary_job_scheduler {
          user {
            id
            mobile
            first_name
          }
        }
        associated_cse {
          user {
            id
            mobile
            first_name
          }
        }
      }
    }
    getKeywordSeoByLocation(keyword: $seoKeyword, city: $location) {
      id
      content
      meta_description
      meta_keyword
      title
      h1
    }
  }
`;

export const GET_RELATED_FAS = gql`
  query listMachingFunctionalAreas($functional_areas: [String]!) {
    listMachingFunctionalAreas(functional_areas: $functional_areas) {
      maching_functional_area {
        id
        name
      }
    }
  }
`;

export const GET_EARNING_CALCULATION = gql`
  query earningCalculator($hourRange: String) {
    earningCalculator(hourRange: $hourRange) {
      earning
    }
  }
`;

export const UDEMY_LISTING_COURSES = gql`
  query getCourses($query: String!) {
    UdemyCoursesQuery {
      GetCoursesByQuery(query: $query) {
        id
        title
        number_enrollment
        weighted_avg_rating
        number_rating
        image_url
        course_url
        popular
        category
        for_all_fa
        sub_category
        fa_categories {
          id
          name
          image
          full_image
        }
        price
      }
    }
  }
`;

export const GET_VARIANT = gql`
  query GetVariant($abTestId: ID!) {
    getVariant(abTestId: $abTestId) {
      variant
    }
  }
`;

export const GET_LOCATION = gql`
  query locationSearch($query: String) {
    searchLocality(query: $query) {
      id
      city {
        id
        name
      }
    }
  }
`;

// ///////////////////// OLD QUERIES START ///////////////////////

/* eslint-disable */

// const filter = `filter: {
//   client_approval_required: $walkIn
//   expectation: { work_exp_requirements: { min_experience: $forFresher } }
// }`;

const filter = `filter: {}`;

const jobFields = `
functional_area {
  name
  id
}
pricing_plan_type
id
title
designations
recent_slot
is_active
is_screening_required
approved_on
client_approval_required
organization {
  id
  name
  popular_name
  slug
  is_hr_agency
}
slug
tags
offer {
  salary_format
  min_offered_salary
  max_offered_salary
}
application_metrics {
  joinings
  selections
  previous_interviews
  upcoming_interviews
}
locations {
  place {
    place_id
    short_formatted_address
    location
    city
    locality
  }
}
places {
  place_id
  short_formatted_address
  location
}
expectation {
  work_exp_requirements {
    min_experience
    max_experience
  }
}
vertical
`;

const edges = `edges {
  ${jobFields}
}`;

const client = `jobSearchFlow @client {
    suggestionState
    isTextPresent
    searchKeywordText
  }`;

const inputParams = `search(
    $searchKeyword: String
    $location: String
    $city: String
    $after: Int
    )`;
// $walkIn: Boolean
// $forFresher: Int

const getSearchQuery = (filters) => {
  let incommingFilters;
  if (filters) {
    incommingFilters = filters;
  } else {
    incommingFilters = filter;
  }
  return gql`
  query
  ${inputParams}{
    ${client}
    searchJobsV2(
      ${incommingFilters}
      query: $searchKeyword
      location: $location
      first: 20
      after: $after
    ) {
      totalCount
      ${edges}
      suggest
    }
    getKeywordSeoByLocation(keyword: $searchKeyword, city: $city) {
      id
      place {
        place_id
        locality
        city
        location
        postal_code
        state
        short_formatted_address
      }
      keyword
      content
      meta_description
      meta_keyword
      title
      h1
    }
  }
`;
};

const SEARCH_JOB = gql`
  query search(
    $searchKeyword: String
    $location: String
    $after: Int
    $walkIn: Boolean
    $forFresher: Int
  ){
  ${client}
    searchJobsV2(
      ${filter}
      query: $searchKeyword
      location: $location
      first: 20
      after: $after
    ) {
      totalCount
      ${edges}
      suggest
    }
  }
`;

export { SEARCH_JOB, getSearchQuery, edges, jobFields };

/////////////////////// OLD QUERIES START ///////////////////////
