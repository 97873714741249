import { buttonType } from "screens/common/constants";
import { GET_CANDIDATE_APPLICATION, JOB_APPLY } from "screens/common/queries";
import { SEARCH_JOB_QUERY } from "screens/JobList/query";
import {
  getApplicationHistoryActionId,
  getSourceId,
  isTrustedJob
} from "screens/common/utils/common";
import { pushUTMEvent } from "utils/clevertapEvents";
import getData from "utils/getData";
import { clientHelper } from "utils/graphQL";
import { mLog } from "utils/logger";

export const getCandidateApplication = async (jobId, client) => {
  let applicationData = null;

  const { data, error } = await clientHelper("query")(client)({
    query: GET_CANDIDATE_APPLICATION,
    variables: {
      id: jobId
    }
  });

  if (error) {
    mLog("ERROR in getting application", error);
  } else {
    const application = getData(data, "nodes.edges.0");
    // Check for correct stage and recent_slot in application
    if (application) {
      applicationData = application;
    }
  }

  return applicationData;
};

export const jobApplyMutation = async ({
  jobGet,
  jobId,
  isWalkIn,
  sources,
  bType,
  client
}) => {
  const payload = {
    id: jobId
  };

  if (jobGet) {
    payload.application_history_action_id = getApplicationHistoryActionId(
      jobGet,
      bType || buttonType.Apply
    );
  }

  if (isWalkIn) {
    // Here only Fix Interview is possible... otherwise normal Apply
    payload.source_id = getSourceId(sources, buttonType.FixInterview);
  }

  if (pushUTMEvent) {
    payload.utm = pushUTMEvent();
  }
  const { data, error } = await clientHelper("mutate")(client)({
    mutation: JOB_APPLY,
    variables: payload
  });

  return {
    data,
    error
  };
};

export const deleteJobFromCache = (jobId, queryVariables, client) => {
  const storeData = client.readQuery({
    query: SEARCH_JOB_QUERY,
    variables: queryVariables
  });
  const modifiedStore = {};

  if (storeData) {
    modifiedStore.getKeywordSeoByLocation = storeData.getKeywordSeoByLocation;
    if (storeData.searchJobsV2) {
      let filteredData = null;
      filteredData = storeData.searchJobsV2.edges.filter(
        (item) => item.id !== jobId
      );
      modifiedStore.searchJobsV2 = { ...storeData.searchJobsV2 };
      if (filteredData) {
        modifiedStore.searchJobsV2.edges = filteredData;
        modifiedStore.searchJobsV2.totalCount = filteredData.length;
      }
    }

    if (storeData.featuredJobs) {
      let filteredData = null;
      filteredData = storeData.featuredJobs.edges.filter(
        (item) => item.id !== jobId
      );
      modifiedStore.featuredJobs = { ...storeData.featuredJobs };
      if (filteredData) {
        modifiedStore.featuredJobs.edges = filteredData;
        modifiedStore.featuredJobs.totalCount = filteredData.length;
      }
    }

    client.writeQuery({
      query: SEARCH_JOB_QUERY,
      variables: queryVariables,
      data: modifiedStore
    });
  } else {
    console.warn(
      `[JobCard/CTA/utils/common.js][deleteJobFromCache] No Store data found to delete job: ${jobId}`
    );
  }
};
