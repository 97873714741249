import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Animated,
  TouchableWithoutFeedback,
  Dimensions,
  Platform
} from "react-native";
import Content from "./Content";

class ActionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: new Animated.Value(props.visible ? 1 : 0)
    };
    this.displayCard = props.visible;
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.visible === true) {
      this.displayCard = true;
    }
    if (nextProps.visible === false) {
      this.hideCard();
      return false;
    }
    return true;
  }

  componentDidUpdate() {
    if (this.displayCard) {
      this.showCard();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  preventDefault = (e) => {
    if (Platform.OS === "web") {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  showCard = () => {
    const { animation } = this.state;
    Animated.timing(animation, {
      toValue: 1,
      duration: 500
    }).start();
  };

  hideCard = () => {
    const { animation } = this.state;
    Animated.timing(animation, {
      toValue: 0,
      duration: 500
    }).start(() => {
      this.displayCard = false;
      if (this.mounted) {
        this.forceUpdate();
      }
    });
  };

  render() {
    const { visible, onCancel, children, containerStyle, ...restProps } =
      this.props;
    const { animation } = this.state;

    if (!this.displayCard) {
      return null;
    }

    const screenHeight = Dimensions.get("window").height;

    const backdrop = {
      transform: [
        {
          translateY: animation.interpolate({
            inputRange: [0, 0.01],
            outputRange: [screenHeight, 0],
            extrapolate: "clamp"
          })
        }
      ],
      opacity: animation.interpolate({
        inputRange: [0.01, 0.5],
        outputRange: [0, 1],
        extrapolate: "clamp"
      })
    };

    const slideUp = {
      transform: [
        {
          translateY: animation.interpolate({
            inputRange: [0.01, 1],
            outputRange: [screenHeight * 0.8, 0],
            extrapolate: "clamp"
          })
        }
      ]
    };

    const overlayStyles = {
      position: "fixed", // Show card even when scrolling
      bottom: 0,
      left: 0,
      right: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 10,
      ...backdrop
    };

    const cardStyles = {
      position: "absolute",
      ...Platform.select({
        web: {
          width: Dimensions.get("window").width < 800 ? "100%" : 860
        },
        android: {
          width: "100%"
        }
      }),
      bottom: 0,
      maxHeight: "100vh",
      backgroundColor: "white",
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      ...slideUp,
      ...containerStyle
    };

    return (
      <TouchableWithoutFeedback onPress={onCancel}>
        <Animated.View style={overlayStyles} onClick={this.preventDefault}>
          <Animated.View style={cardStyles}>
            <Content onCancel={onCancel} {...restProps}>
              {children}
            </Content>
          </Animated.View>
        </Animated.View>
      </TouchableWithoutFeedback>
    );
  }
}

const WebActionCardWithPortal = (props) => {
  if (!process.browser) {
    return null;
  }

  return ReactDOM.createPortal(
    <ActionCard {...props} />,
    document.getElementById("__next")
  );
};

export default WebActionCardWithPortal;
