import React from "react";
import gql from "graphql-tag";
// eslint-disable-next-line import/no-cycle
import { IstokenExpired } from "./storage";
import { SetLocation } from "./";
import { SetStorage, GetStorage } from "./storage";
import { mLog } from "utils/logger";
import {
  retrieveDataFromAsyncStorage,
  storeDataToAsyncStorage
} from "screens/common/utils";

export class PComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: { name: "India" }
    };
  }

  componentDidMount() {
    this.props.promise.then((obj) => {
      this.setState({ value: obj });
    });
  }

  componentDidUpdate() {
    if (!(typeof this.props.promise === "string")) {
      this.props.promise.then((obj) => {
        if (obj.name !== this.state.value.name) {
          this.setState({ value: obj });
        }
      });
    }
  }

  render() {
    const then = this.props.then || ((value) => <span>{value.name}</span>);
    // const then = value => {
    //   return <span>{value}</span>;
    // };
    return then(this.state.value);
  }
}

export async function getFromIp(client, res = undefined) {
  let locationIs = null;
  try {
    const { data } = await client.query({
      query: gql`
        query {
          getCityFromIP {
            name
            country
            latitude
            longitude
          }
        }
      `
    });
    mLog("get location from IP", data);
    locationIs =
      data.getCityFromIP.name && data.getCityFromIP.country === "India"
        ? data.getCityFromIP.name
        : "India";

    SetLocation(
      "ip_detected",
      locationIs,
      data.getCityFromIP.latitude,
      data.getCityFromIP.longitude,
      null,
      locationIs
    );
  } catch (err) {
    locationIs = "India";
    mLog("network error so seting india");
    SetLocation("ip_detected", "India", null, null, null, "India");
  }
  return locationIs;
}
